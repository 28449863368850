import { ResourcesWrapper } from "../../../../src/collections/resources/Resources.style.js";
import { HPEfacts, HPEintro, HPEbenefits } from "../../../../src/collections/resources/case-study/hpes-adoption-of-meshery-and-kanvas/hpe.style.js";
import Blockquote from "../../../../src/reusecore/Blockquote";
import { Link } from "gatsby";
import Button from "../../../../src/reusecore/Button";
import GlobeIcon from "../../../../src/collections/resources/case-study/hpes-adoption-of-meshery-and-kanvas/globe.svg";
import UsersIcon from "../../../../src/collections/resources/case-study/hpes-adoption-of-meshery-and-kanvas/users.svg";
import NetworkIcon from "../../../../src/collections/resources/case-study/hpes-adoption-of-meshery-and-kanvas/network.svg";
import LayersIcon from "../../../../src/collections/resources/case-study/hpes-adoption-of-meshery-and-kanvas/layers.svg";
import InlineQuotes from "../../../../src/components/Inline-quotes";
import MesheryIntegration from "../../../../src/collections/resources/case-study/hpes-adoption-of-meshery-and-kanvas/meshery-integrations.svg";
import Maxi from "../../../../src/collections/members/maximiliano-churichi/Maximiliano-Churichi.webp";
import Yogi from "../../../../src/collections/resources/case-study/hpes-adoption-of-meshery-and-kanvas/yogi.webp";
import * as React from 'react';
export default {
  ResourcesWrapper,
  HPEfacts,
  HPEintro,
  HPEbenefits,
  Blockquote,
  Link,
  Button,
  GlobeIcon,
  UsersIcon,
  NetworkIcon,
  LayersIcon,
  InlineQuotes,
  MesheryIntegration,
  Maxi,
  Yogi,
  React
};