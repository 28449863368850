import { Link } from "gatsby";
import Formula from "../../../../src/collections/news/2022/2022-05-18-meshmark-the-cloud-native-value-measurement/MeshMark-Formula.webp";
import MeshMapMeshmark from "../../../../src/collections/news/2022/2022-05-18-meshmark-the-cloud-native-value-measurement/meshmark-score.webp";
import { NewsWrapper } from "../../../../src/collections/news/News.style.js";
import Blockquote from "../../../../src/reusecore/Blockquote";
import BlockquoteAlt from "../../../../src/reusecore/Blockquote/Blockquote-alt-style";
import * as React from 'react';
export default {
  Link,
  Formula,
  MeshMapMeshmark,
  NewsWrapper,
  Blockquote,
  BlockquoteAlt,
  React
};